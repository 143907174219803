import {Redirect, Route} from "react-router-dom";
import LocalStorageService from "../services/Storage/LocalStorageService";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  console.log(Component, rest);
  return (
    <Route {...rest} render={props => {
      return (
        LocalStorageService.getAccessToken()
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )}} />
  );
};
