export const logoNegative = ['512 68', `
  <title>coreui react pro logo</title>
  <g transform="matrix(0.319789, 0, 0, 0.319789, 110.950783, 26.205805)">
    <path d="M -41.505 17.067 L -41.553 17.067 C -54.932 17.045 -61.615 8.617 -66.985 1.843 C -71.935 -4.403 -75.036 -7.937 -81.195 -7.947 L -81.217 -7.947 C -87.353 -7.947 -90.457 -4.424 -95.417 1.797 C -100.795 8.548 -107.491 16.945 -120.835 16.945 L -120.884 16.945 C -134.252 16.924 -140.924 8.492 -146.284 1.715 C -151.224 -4.525 -154.319 -8.06 -160.459 -8.069 L -160.48 -8.069 C -166.613 -8.069 -169.716 -4.545 -174.672 1.676 C -180.052 8.428 -186.744 16.825 -200.085 16.825 L -200.135 16.825 C -213.503 16.804 -220.179 8.372 -225.543 1.599 C -230.483 -4.645 -233.577 -8.18 -239.723 -8.188 C -243.681 -8.193 -246.889 -11.409 -246.884 -15.373 C -246.877 -19.329 -243.668 -22.533 -239.709 -22.533 L -239.697 -22.533 C -226.329 -22.512 -219.656 -14.083 -214.291 -7.308 C -209.351 -1.063 -206.256 2.469 -200.109 2.48 L -200.085 2.48 C -193.956 2.48 -190.853 -1.045 -185.895 -7.265 C -180.519 -14.015 -173.821 -22.416 -160.48 -22.416 L -160.437 -22.416 C -147.067 -22.395 -140.396 -13.963 -135.035 -7.185 C -130.095 -0.945 -127 2.589 -120.859 2.597 L -120.835 2.597 C -114.7 2.597 -111.597 -0.925 -106.637 -7.147 C -101.257 -13.895 -94.563 -22.295 -81.217 -22.295 L -81.169 -22.295 C -67.792 -22.272 -61.109 -13.843 -55.737 -7.071 C -50.788 -0.825 -47.689 2.711 -41.531 2.72 L -41.505 2.72 C -35.364 2.72 -32.253 -0.804 -27.292 -7.023 C -21.911 -13.773 -15.216 -22.173 -1.864 -22.173 L -1.816 -22.173 C 2.144 -22.165 5.351 -18.949 5.345 -14.987 C 5.339 -11.029 2.128 -7.825 -1.828 -7.825 L -1.864 -7.825 C -8.005 -7.825 -11.116 -4.3 -16.077 1.919 C -21.46 8.669 -28.157 17.067 -41.505 17.067" style="fill:#f1f2f2;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path22"/>
    <path d="M -41.565 79.32 L -81.185 41.833 L -120.895 79.208 L -160.453 41.717 L -200.144 79.085 L -243.995 37.543 L -235.412 28.485 L -200.117 61.925 L -160.423 24.556 L -120.864 62.045 L -81.16 24.677 L -41.54 62.171 L -6.092 28.833 L 2.456 37.923 Z" style="fill:#36ad73;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path26"/>
    <path d="M 105.331 78.476 C 104.322 78.476 103.463 77.606 103.463 76.595 L 104.535 32.78 L 60.134 32.708 L 61.077 76.534 C 61.077 77.545 60.209 78.406 59.199 78.406 L 39.881 78.377 C 38.874 78.377 38.013 77.507 38.013 76.499 L 39.386 27.92 L 38.165 -20.663 C 38.165 -21.672 39.03 -22.533 40.037 -22.533 L 59.354 -22.503 C 60.365 -22.503 61.229 -21.634 61.229 -20.627 L 60.306 16.418 L 104.419 16.49 L 103.61 -20.564 C 103.61 -21.573 104.478 -22.434 105.486 -22.434 L 124.804 -22.405 C 125.813 -22.405 126.676 -21.535 126.676 -20.53 L 125.303 28.05 L 126.527 76.633 C 126.527 77.642 125.659 78.507 124.648 78.507 Z" style="fill:#f1f2f2;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path30" clip-path="url(#clipPath18)"/>
    <path d="M 195.541 78.613 C 194.53 78.611 193.667 77.745 193.667 76.732 L 193.966 69.962 C 188.769 76.298 181.408 80.034 172.039 80.021 C 150.993 79.988 139.917 63.245 139.948 41.624 C 139.982 20.141 152.41 2.862 172.593 2.893 C 181.816 2.904 189.014 6.237 194.055 12.298 L 193.775 6.244 C 193.636 5.233 194.497 4.37 195.508 4.372 L 213.815 4.397 C 214.825 4.397 215.691 5.267 215.689 6.275 L 214.768 41.59 L 215.581 76.768 C 215.581 77.779 214.714 78.64 213.701 78.64 Z M 178.116 65.614 C 188.639 65.625 194.276 57.707 194.442 41.992 C 194.61 26.136 189.001 17.482 178.622 17.32 C 167.666 17.016 162.033 25.222 162.012 40.504 C 161.985 57.225 167.735 65.74 178.116 65.614" style="fill:#f1f2f2;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path34" clip-path="url(#clipPath18)"/>
    <path d="M 276.162 77.44 C 275.872 78.448 274.861 79.164 273.707 79.164 L 250.787 79.131 C 249.633 79.131 248.626 78.406 248.338 77.396 L 224.229 6.147 C 223.941 5.279 224.518 4.416 225.527 4.416 L 245.133 4.445 C 246.144 4.447 247.15 5.172 247.577 6.328 L 263.067 62.14 L 280.597 6.231 C 280.886 5.22 281.896 4.5 283.048 4.504 L 299.624 4.527 C 300.635 4.532 301.21 5.395 300.924 6.263 Z" style="fill:#f1f2f2;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path38" clip-path="url(#clipPath18)"/>
    <path d="M 352.233 80.295 C 343.006 80.28 335.807 76.947 330.77 70.889 L 331.05 76.943 C 331.193 77.954 330.323 78.819 329.317 78.817 L 311.006 78.787 C 309.999 78.785 309.138 77.922 309.138 76.909 L 310.075 28.328 L 309.285 -20.252 C 309.285 -21.259 310.155 -22.124 311.161 -22.122 L 329.614 -22.095 C 330.625 -22.093 331.486 -21.227 331.486 -20.219 L 331.001 13.083 C 336.057 6.747 343.56 3.152 352.783 3.167 C 373.834 3.201 384.906 19.941 384.872 41.563 C 384.838 63.043 372.417 80.327 352.233 80.295 M 346.205 65.58 C 357.156 65.884 362.793 57.389 362.817 42.397 C 362.84 26.109 357.087 17.734 346.708 17.861 C 336.186 17.846 330.553 25.625 330.382 40.904 C 330.216 56.764 335.824 65.42 346.205 65.58" style="fill:#f1f2f2;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path42" clip-path="url(#clipPath18)"/>
    <path d="M 398.864 78.922 C 397.856 78.922 396.994 78.053 396.994 77.042 L 397.767 41.729 L 397.102 6.553 C 397.104 5.54 397.969 4.679 398.978 4.679 L 416.997 4.709 C 418.007 4.709 418.871 5.574 418.723 6.585 L 418.277 17.25 C 424.059 8.467 434.011 4.155 444.823 4.172 C 445.687 4.176 446.698 4.319 447.561 4.469 C 448.567 4.61 449.433 5.618 449.429 6.633 L 449.412 18.88 C 449.41 19.893 448.69 20.615 447.538 20.615 C 446.813 20.468 446.093 20.468 445.373 20.468 C 430.092 20.445 418.69 28.926 418.807 45.938 L 419.05 77.08 C 419.05 78.088 418.182 78.954 417.173 78.954 Z" style="fill:#f1f2f2;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path46" clip-path="url(#clipPath18)"/>
    <path d="M 484.872 80.782 C 469.159 80.758 459.229 70.074 459.256 51.91 L 459.279 36.343 L 458.749 6.646 C 458.753 5.633 459.618 4.772 460.625 4.774 L 478.789 4.799 C 479.795 4.799 480.663 5.669 480.661 6.68 L 480.041 36.374 L 480.018 51.653 C 480.004 59.727 485.045 64.926 491.528 64.936 C 499.317 64.944 507.685 57.751 507.71 41.893 L 507.719 36.414 L 507.47 6.717 C 507.473 5.709 508.34 4.848 509.351 4.848 L 527.805 4.875 C 528.814 4.875 529.675 5.742 529.671 6.753 L 528.761 40.482 L 529.279 77.244 C 529.279 78.255 528.412 79.118 527.403 79.114 L 510.25 79.091 C 509.239 79.089 508.378 78.223 508.521 77.212 L 508.82 69.718 C 503.764 76.2 495.251 80.8 484.872 80.782" style="fill:#f1f2f2;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path50" clip-path="url(#clipPath18)"/>
    <path d="M 582.156 63.633 L 572.958 47.183 L 565.881 55.966 L 566.279 77.301 C 566.279 78.312 565.411 79.175 564.398 79.171 L 546.234 79.145 C 545.228 79.145 544.365 78.276 544.365 77.267 L 545.449 28.688 L 544.514 -19.895 C 544.514 -20.903 545.384 -21.764 546.39 -21.764 L 564.558 -21.737 C 565.567 -21.735 566.428 -20.869 566.428 -19.861 L 565.491 28.286 L 565.626 32.757 L 586.86 6.408 C 587.58 5.545 588.73 4.97 589.888 4.97 L 609.924 4.999 C 610.644 5.003 611.076 5.867 610.496 6.446 L 587.972 31.634 L 614.428 77.661 C 614.714 78.387 614.278 79.246 613.415 79.246 L 592.08 79.215 C 590.926 79.215 589.915 78.493 589.347 77.48 Z" style="fill:#f1f2f2;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path54" clip-path="url(#clipPath18)"/>
    <path d="M 655.135 81.043 C 634.811 81.011 619.109 72.485 617.982 56.191 C 617.982 55.178 618.852 54.315 619.858 54.319 L 637.016 54.346 C 638.17 54.346 639.033 55.071 639.174 56.223 C 639.88 64.153 645.211 67.623 655.159 67.636 C 663.666 67.648 668.281 64.34 668.292 58.573 C 668.311 44.445 619.709 58.067 619.757 26.93 C 619.781 11.508 632.189 3.308 652.227 3.336 C 671.543 3.365 684.51 11.028 686.07 26.164 C 686.211 27.17 685.348 28.036 684.339 28.036 L 668.048 28.008 C 666.894 28.008 666.03 27.286 665.746 26.136 C 664.453 20.219 660.427 16.467 651.92 16.456 C 644.857 16.442 640.381 19.034 640.372 25.374 C 640.351 39.501 688.957 25.159 688.911 56.732 C 688.884 72.302 674.165 81.074 655.135 81.043" style="fill:#f1f2f2;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path58" clip-path="url(#clipPath18)"/>
    <path d="M 702.935 79.383 C 701.924 79.383 701.061 78.514 701.061 77.503 L 702.145 28.924 L 701.21 -19.659 C 701.21 -20.667 702.08 -21.526 703.091 -21.526 L 721.253 -21.499 C 722.263 -21.499 723.125 -20.632 723.125 -19.625 L 722.038 28.956 L 722.975 77.537 C 722.975 78.547 722.107 79.415 721.097 79.415 Z" style="fill:#f1f2f2;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path62" clip-path="url(#clipPath18)"/>
    <path d="M 773.207 81.367 C 748.989 81.327 735.026 66.172 735.064 42.531 C 735.1 18.884 749.107 3.34 773.325 3.38 C 797.547 3.416 811.217 18.568 811.181 42.211 C 811.143 65.854 797.427 81.405 773.207 81.367 M 773.23 66.951 C 783.897 66.962 788.955 59.621 788.981 42.613 C 789.008 25.888 783.83 17.806 773.162 17.791 C 762.204 17.916 757.149 25.983 757.119 42.708 C 757.094 59.43 762.419 66.934 773.23 66.951" style="fill:#f1f2f2;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path66" clip-path="url(#clipPath18)"/>
    <path d="M 859.181 108.451 C 839.863 108.714 825.315 100.906 823.897 85.046 C 823.754 84.035 824.618 83.169 825.628 83.174 L 843.068 83.201 C 844.226 83.201 845.089 83.927 845.376 85.079 C 846.519 90.845 851.417 94.172 859.92 94.18 C 869.29 94.195 875.786 89.017 875.803 77.343 L 875.816 68.836 C 870.907 75.315 863.693 79.196 854.466 79.183 C 832.411 79.148 820.901 62.839 820.933 41.935 C 820.964 21.177 833.39 4.33 853.573 4.359 C 862.8 4.376 870.002 7.56 875.039 13.475 L 874.755 7.278 C 874.616 6.267 875.479 5.406 876.492 5.406 L 894.365 5.431 C 895.374 5.435 896.241 6.3 896.239 7.311 L 895.605 43.639 L 896.132 77.659 C 896.391 96.4 883.687 108.488 859.181 108.451 M 859.101 65.062 C 869.627 65.079 875.26 57.593 875.424 42.597 C 875.59 27.172 869.985 18.657 859.604 18.499 C 848.648 18.191 843.015 26.258 842.992 41.11 C 842.967 57.111 848.724 65.187 859.101 65.062" style="fill:#f1f2f2;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path70" clip-path="url(#clipPath18)"/>
    <path d="M 946.561 108.585 C 927.244 108.844 912.695 101.041 911.278 85.18 C 911.135 84.168 911.998 83.302 913.007 83.308 L 930.451 83.334 C 931.604 83.334 932.47 84.06 932.756 85.212 C 933.9 90.982 938.798 94.302 947.303 94.317 C 956.673 94.33 963.167 89.152 963.186 77.478 L 963.196 68.971 C 958.288 75.448 951.072 79.326 941.847 79.316 C 919.791 79.282 908.282 62.976 908.313 42.072 C 908.345 21.314 920.771 4.462 940.954 4.496 C 950.183 4.506 957.383 7.69 962.419 13.612 L 962.135 7.41 C 961.996 6.402 962.859 5.538 963.872 5.538 L 981.746 5.566 C 982.754 5.568 983.622 6.433 983.62 7.446 L 982.986 43.772 L 983.512 77.791 C 983.771 96.53 971.068 108.625 946.561 108.585 M 946.483 65.195 C 957.008 65.21 962.641 57.728 962.807 42.731 C 962.973 27.305 957.366 18.789 946.985 18.632 C 936.029 18.326 930.398 26.393 930.375 41.245 C 930.347 57.244 936.104 65.324 946.483 65.195" style="fill:#f1f2f2;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path74" clip-path="url(#clipPath18)"/>
    <path d="M 1067.757 54.854 C 1068.766 54.858 1069.482 55.724 1069.343 56.732 C 1067.305 70.135 1054.308 81.794 1033.839 81.765 C 1009.04 81.727 995.664 65.414 995.694 42.925 C 995.73 19.28 1009.884 3.736 1033.523 3.772 C 1057.745 3.807 1071.27 19.83 1071.809 45.345 C 1071.809 46.355 1070.939 47.219 1069.928 47.217 L 1017.454 47.139 C 1018.303 61.123 1023.769 67.33 1034.15 67.347 C 1041.217 67.358 1046.404 64.191 1048.44 56.699 C 1048.728 55.547 1049.737 54.829 1050.891 54.829 Z M 1033.649 18.042 C 1024.999 18.027 1019.8 23.211 1018.054 33.443 L 1048.478 33.489 C 1047.621 25.564 1043.163 18.057 1033.649 18.042" style="fill:#f1f2f2;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path78" clip-path="url(#clipPath18)"/>
    <path d="M 1084.984 79.965 C 1083.976 79.965 1083.11 79.095 1083.11 78.084 L 1084.03 44.359 L 1083.51 7.598 C 1083.512 6.583 1084.376 5.724 1085.384 5.724 L 1102.54 5.749 C 1103.548 5.751 1104.416 6.618 1104.264 7.627 L 1103.969 15.126 C 1109.025 8.642 1117.532 4.041 1127.918 4.058 C 1143.628 4.081 1153.557 14.768 1153.529 32.929 L 1153.506 48.501 L 1154.041 78.196 C 1154.041 79.206 1153.171 80.066 1152.163 80.066 L 1133.997 80.04 C 1132.988 80.038 1132.127 79.173 1132.127 78.162 L 1132.75 48.465 L 1132.771 33.186 C 1132.784 25.113 1127.749 19.916 1121.259 19.905 C 1113.475 19.895 1105.102 27.09 1105.079 42.944 L 1105.073 48.423 L 1105.311 78.12 C 1105.311 79.131 1104.443 79.996 1103.437 79.996 Z" style="fill:#f1f2f2;fill-opacity:1;fill-rule:nonzero;stroke:none" id="path82" clip-path="url(#clipPath18)"/>
  </g>
`]
